<style scoped>
.width-xs {
  width: 180px;
}

.ivu-btn-info {
  border: 1px solid;
}

.tab-cnt {
  padding: 0 0 20px 30px;
}

.content-head .ivu-form-item {
  margin-bottom: 10px;
}

.couponcss {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 5%;
  display: flex;
  align-items: center;
  width: 210px;
}

.redtext {
  font-size: 20px;
  font-weight: 500;
  color: #d0251a;
}

.couponremark {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.couponrule {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
  color: #222222;
  word-break: keep-all;
}

.coupontime {
  word-break: keep-all;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
}

.couponuse {
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 5%;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  word-break: keep-all;
}
</style>
<template>
  <div class="page-main">
    <div class="page-content content-main">
      <div class="content-head">
        <p class="title">消息管理（共{{ pager.total }}条数据）</p>
        <div style="padding-top: 34px">
          <Form
            class="custom-form"
            :model="formData"
            label-position="right"
            :label-width="95"
            inline
          >
            <Row>
              <FormItem label="消息编号">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.pfMessageConfigCode"
                  clearable
                />
              </FormItem>
              <FormItem label="消息标题">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.messageTitle"
                  clearable
                />
              </FormItem>
              <FormItem label="消息类型">
                <Select
                  v-model="formData.businessType"
                  clearable
                  class="width-xs"
                >
                  <Option
                    v-for="(item, key) in businessTypeList"
                    :value="item.value"
                    :key="key"
                  >
                    {{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="状态">
                <Select v-model="formData.status" clearable class="width-xs">
                  <Option
                    v-for="(val, key) in MESSAGE_STATUS"
                    :value="val.value"
                    :key="key"
                    >{{ val.label }}</Option
                  >
                </Select>
              </FormItem>

              <FormItem label="模板编号">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.pfModelCode"
                  clearable
                />
              </FormItem>
            </Row>
            <Row style="justify-content: flex-end">
              <Button type="primary" @click="queryData" style="margin-left: 5px"
                >查询</Button
              >
              <Button
                type="primary"
                @click="clearFormData"
                style="margin-left: 5px"
                >重置</Button
              >
              <Button
                type="primary"
                @click="addData"
                style="margin-left: 5px"
                v-show="tabIndex == 0"
                >新增</Button
              >
            </Row>
          </Form>
        </div>
      </div>
      <div class="page-content-item">
        <sp-table
          :columns="columns"
          :data="dataList"
          :loading="loading"
          :show-total="false"
          :total="pager.total"
          :page="pager.pageNum"
          :page-size="pager.pageSize"
          @change="changePage"
        >
        </sp-table>
      </div>
    </div>
    <Modal title="审核" v-model="modalForm.show" width="700">
      <Form
        ref="modalForm"
        label-position="right"
        :label-width="130"
        style="margin-top: 20px"
        :model="modalForm"
        :rules="modalRule"
      >
        <FormItem label="确认结果" prop="status">
          <RadioGroup v-model="modalForm.status">
            <Radio label="0">通过</Radio>
            <Radio label="1">驳回</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="请输入驳回原因"
          v-if="modalForm.status === '1'"
          prop="status"
        >
          <Input
            type="textarea"
            v-model="modalForm.refuseReason"
            maxlength="100"
            show-word-limit
          ></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" size="large" @click="modalForm.show = false"
          >取消</Button
        >
        <Button type="primary" size="large" @click="submit">确定</Button>
      </div>
    </Modal>
    <Modal title="操作日志" v-model="logDialogDisplay" width="800">
      <div class="page-content-item">
        <Table
          :columns="logCol"
          :data="loglist"
          :loading="logLoading"
          border
        ></Table>
      </div>
      <div slot="footer">
        <Button type="text" size="large" @click="logDialogDisplay = false"
          >取消</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
  import { MESSAGE_STATUS } from '@/util/enum';
  export default {
    data() {
      return {
        businessTypeList: [], // 消息类型
        tabIndex: 0, // 当前tab
        modalRule: {
          status: [
            {
              required: true,
              message: '请选择确认结果',
              trigger: 'change',
            },
          ],
          refuseReason: [
            {
              required: true,
              message: '请输入驳回原因',
              trigger: 'blur',
            },
          ],
        },
        modalForm: {
          show: false,
          staus: '',
          refuseReason: '',
        },
        MESSAGE_STATUS,
        formData: {
          // 查询数据
          businessType: '',
          channelType: '',
          id: '',
          messageTitle: '',
          pfMessageConfigCode: '',
          pfModelCode: '',
          sendDateBegin: '',
          sendDateEnd: '',
          sendLevel: '',
          status: '',
          templateName: '',
          triggerWay: 0,
        },
        formParams: {}, // 提交查询的参数，避免填写了表单数据但并未提交查询时点击翻页按钮导致误把查询条件带进去
        loadState: false,
        columns: [
          {
            title: '消息编号',
            key: 'pfMessageConfigCode',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '消息类型',
            key: 'businessType',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              let content = this.businessTypeList.filter(
                (v) => v.value === row.businessType
              );
              return h('div', content[0] && content[0].label);
            },
          },
          {
            title: '消息标题',
            key: 'messageTitle',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '模板编号',
            key: 'pfModelCode',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '推送时间',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              // let text = (row.sendDateBegin||'').substr(0,19) + '-' + (row.sendDateEnd||'').substr(0,19)
              return h('div', [
                h('div', (row.sendDateBegin || '').substr(0, 19)),
                h('div', (row.sendDateEnd || '').substr(0, 19)),
              ]);
            },
          },
          {
            title: '状态',
            key: 'status',
            align: 'center',
            minWidth: 80,
            render: (h, { row }) => {
              // 3：未推送 1：已发布，4：推送中，5结束
              let colors = [
                '#19be6b',
                '#2db7f5',
                '#ed4014',
                '#ed4014',
                '#19be6b',
                '#ed4014',
              ];
              // #2db7f5 已发布
              // #19be6b 推送中
              // #ed4014 未推送，已结束

              let content = MESSAGE_STATUS.filter((v) => v.value === row.status);
              return h(
                'div',
                {
                  style: {
                    color: colors[row.status],
                  },
                },
                content[0] && content[0].label
              );
            },
          },
          {
            title: '操作',
            width: 200,
            align: 'center',
            render: (h, { row }) => {
              let buttons = [];
              let status = row.status;
              let btnList = {
                edit: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.editData(row.pfMessageConfigCode);
                      },
                    },
                  },
                  '编辑'
                ),
                detail: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.detailData(row.pfMessageConfigCode);
                      },
                    },
                  },
                  '查看'
                ),
                enable: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.triggerData(row.pfMessageConfigCode, 1);
                      },
                    },
                  },
                  '发布'
                ),
                check: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.checkData(row.pfMessageConfigCode);
                      },
                    },
                  },
                  '审核'
                ),
                cancel: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.cancelData(row.pfMessageConfigCode, 2);
                      },
                    },
                  },
                  '终止'
                ),
                log: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.logData(row.pfMessageConfigCode);
                      },
                    },
                  },
                  '日志'
                ),
              };
              // // 1：已发布 3：未推送，4：推送中，2终止5,结束
              // eslint-disable-next-line eqeqeq
              if (status == 1) {
                buttons.push(btnList.detail);
                buttons.push(btnList.cancel);
              }
              // eslint-disable-next-line eqeqeq
              if (status == 3) {
                // eslint-disable-next-line eqeqeq
                if (this.tabIndex != 1) {
                  buttons.push(btnList.edit);
                }
                buttons.push(btnList.enable);
              }
              // eslint-disable-next-line eqeqeq
              if (status == 4) {
                buttons.push(btnList.detail);
                buttons.push(btnList.cancel);
              }
              // eslint-disable-next-line eqeqeq
              if ((status == 5 || status == 2) && this.tabIndex != 1) {
                buttons.push(btnList.edit);
              }

              return h('div', buttons);
            },
          },
        ],
        dataList: [],
        loading: false, // 表格查询状态
        showloading: false,
        btnLoading: false,
        pager: {
          total: 0, // 总条数
          pageNum: 1, // 当前页码
          pageSize: 10, // 每页条数
        },
        loglist: [],
        logDialogDisplay: false,
        logLoading: false,
        logCol: [
          {
            title: '时间',
            key: 'createTime',
            align: 'center',
          },
          {
            title: '操作人',
            key: 'createdBy',
            align: 'center',
          },
          {
            title: '业务类型',
            key: 'status',
            align: 'center',
          },
          {
            title: '备注',
            key: 'opinion',
            align: 'center',
          },
        ],
      };
    },
    created() {
      this.queryData();
      this.queryBusinessTypeList();
    },
    methods: {
      /**
       * 获取消息类型
       */
      queryBusinessTypeList() {
        let data = {
          subType: '',
          type: 'busniess_type',
        };
        this.$axios({
          url: this.$api.message.queryBusinessTypeList,
          data,
        }).then((data) => {
          this.businessTypeList = data || [];
        });
      },
      changeTab(index) {
        this.tabIndex = index || 0;
        this.queryData();
      },
      /**
       * 日志查看
       */
      logData(pfMessageConfigCode) {
        let data = { pfMessageConfigCode: pfMessageConfigCode };
        this.$axios({
          url: this.$api.message.queryMessageConfigLogList,
          data,
        }).then((res) => {
          this.loglist = res;
          this.logDialogDisplay = true;
        });
      },
      /**
       * 审核确认
       */
      submit() {
        this.$refs.modalForm.validate((valid) => {
          if (valid) {
            let data  = {
              type: 1,
              eventId: this.modalForm.pfMessageConfigCode,
              status: this.modalForm.status,
              refuseReason: this.modalForm.refuseReason,
            };
            this.$axios({
              url: this.$api.message.updateMessageConfigStatus,
              data,
            }).then((res) => {
              this.modalForm.show = false;
              this.handleSuccess();
            });
          }
        });
      },
      /**
       * 审核
       */
      checkData(pfMessageConfigCode) {
        this.modalForm = {
          pfMessageConfigCode,
          show: true,
          staus: '',
          refuseReason: '',
        };
      },
      /**
       * 作废模板
       */
      cancelData(pfMessageConfigCode) {
        this.$confirm('选中消息将被终止，请确认是否继续').then(() => {
          let data  = {
            pfMessageConfigCode,
            status: 2,
          };
          this.$axios({
            url: this.$api.message.updateMessageConfigStatus,
            data,
          }).then((res) => {
            this.handleSuccess();
          });
        });
      },
      /**
       * 执行模板
       */
      triggerData(pfMessageConfigCode) {
        this.$confirm('选中消息将被发布，请确认是否继续').then(() => {
          let data  = {
            pfMessageConfigCode,
            status: 1,
          };
          this.$axios({
            url: this.$api.message.updateMessageConfigStatus,
            data,
          }).then((res) => {
            this.handleSuccess();
          });
        });
      },
      /**
       * 操作成功
       */
      handleSuccess() {
        this.notice({
          type: 'success',
          desc: '操作成功',
        });
        this.getData();
      },
      /**
       * 获取数据
       */
      getData() {
        this.formParams.pageNum = this.pager.pageNum;
        this.formParams.pageSize = this.pager.pageSize;
        this.formParams.triggerWay = this.tabIndex || 0; // 手动or自动推送
        if (this.formData.status) {
          this.formParams.status = [this.formData.status];
        } else {
          delete this.formParams.status;
        }
        this.loading = true;
        this.$axios({
          url: this.$api.message.getMessageConfigList,
          data: this.formParams,
          complete: () => {
            this.loading = false;
          }
        }).then((data) => {
          this.dataList = data.list || [];
          this.pager.total = Number(data.total) || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.formParams = Object.assign({}, this.formData);
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      },
      /**
       * 重置查询条件
       */
      clearFormData() {
        let tmpformData = {
          businessType: '',
          channelType: '',
          id: '',
          messageTitle: '',
          pfMessageConfigCode: '',
          pfModelCode: '',
          sendDateBegin: '',
          sendDateEnd: '',
          sendLevel: '',
          status: '',
          templateName: '',
          triggerWay: 0,
        };
        this.formData = tmpformData;
      },
      /**
       * 新增数据
       */
      addData() {
        this.$router.push({
          name: 'messageCentermanageAdd',
        });
      },
      detailData(pfMessageConfigCode) {
        this.$router.push({
          name: 'messageCentermanageDetail',
          query: {
            pfMessageConfigCode,
          },
        });
      },
      /**
       * 编辑数据
       */
      editData(pfMessageConfigCode) {
        this.$router.push({
          name: 'messageCentermanageEdit',
          query: {
            pfMessageConfigCode,
          },
        });
      },
    },
  };
</script>
